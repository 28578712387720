import React, { useEffect, useState } from 'react';
import { Carousel, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Slider() {

  const [banner, setBanner] = useState(null);

  useEffect(() => {  
    const auth = JSON.parse(JSON.parse(localStorage.getItem('persist:american-burrs')).parceiro).cnpjRevenda;
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fetchUser = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/revendas/cnpj/${auth}`, {
          method: 'GET',          
        });
        const data = await response.json();
        
        setBanner(data.bannerExclusivo);
      } catch (error) {
        console.error('Erro ao buscar banner:', error);
      }
    };

    fetchUser();
  }, []);
  
  return (
    <Container fluid="md" className="mb-5">
      <Row>
        <div className="d-none d-sm-block">
          <Carousel interval={3000}>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-produtos.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-produtos.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/premio/2112">
                  <img
                    className="d-block w-100"
                    src="imagens/banner-airfryier.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/premio/2112">
                  <img
                    className="d-block w-100"
                    src="imagens/banner-airfryer-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            {banner && (
            <Carousel.Item>
              <div className="d-none d-md-flex">
              <Link to="" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-exclusivo.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
              <Link to="" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-exclusivo.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            )}
          </Carousel>
        </div>
        <div className="d-sm-none">
          <Carousel indicators={false} interval={3000}>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-mobile-produtos.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-mobile-produtos.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/premio/2112">
                  <img
                    className="d-block w-100"
                    src="imagens/banner-airfryier.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/premio/2112">
                  <img
                    className="d-block w-100"
                    src="imagens/banner-airfryer-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </Row>
    </Container>
  );
}
